<template>
    <transition name="jgk">
  <div class="plan-apartment-container" :data-apartment="apartment" :class="planContainerClass">
      <div class="plan-apartment-previous">
        <router-link :to="prevFlatLink" class="menu-item py-1 px-3">Попереднє житло</router-link>
      </div>
      <div class="plan-apartment-data">
        <router-link :to="'/plan/floor/' + floor" class="comeback-link">Повернутися до поверху</router-link>
        <div class="row">
          <div class="col-md-8">
            <h1 class="main-title">Житло №{{ apartment }}</h1>
            {{ floor }} поверх
          </div>
          <div class="col-md-4">
            <!-- <a class="gent-button" @click="goToFloor(1)">Залишити заявку</a> -->
              <ContactForm />
          </div>
        </div>
        <div v-swiper:planSwiper="swiperOptions" class="plan-apartment-swiper">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="(item, imageIndex) in planImages" :key="imageIndex" >
              <div class="plan-apartment-image-block">
                <img :src="item" class="plan-apartment-slide-image" />
              </div>
            </div>
            <div class="swiper-slide" v-if="this.$flats[apartment].visualisations">
              <router-link :to="'/gallery/' + apartment" class="plan-apartment-gallery-block" :style="'background-image: url(' + this.$flats[apartment].visualisations.plans[0] + ')'">
                <h5 class="gallery-general-title">Переглянути варіант дизайну</h5>
                <div class="gallery-general-count">11</div>
              </router-link>
            </div>
          </div>
          <div class="swiper-pagination" slot="pagination"></div>
          <div class="swiper-button-prev" slot="button-prev"></div>
          <div class="swiper-button-next" slot="button-next"></div>
        </div>
        <span class="specifications-tag" v-for="(areaData, areaDataIndex) in this.$flats[this.apartment.toString()].areas" v-bind:key="areaDataIndex">{{ areaData.name }} <strong v-html="areaData.value + (areaData.unit ? ' ' + areaData.unit : '') "></strong></span>
      </div>
      <div class="plan-apartment-next">
        <router-link :to="nextFlatLink" class="menu-item py-1 px-3">Наступне житло</router-link>
      </div>
  </div>
    </transition>
</template>

<script>
import ContactForm from '@/components/ContactForm'

export default {
  name: 'carrousel',
  data() {
    return {
      clientName: '',
      planContainerClass: 'plan-apartment-container',
      swiperOptions: {
        autoHeight: true, //enable auto height
        slidesPerView: 1,
        centeredSlides: true,
        spaceBetween: 15,
        breakpoints: {
          '480': {
            // slidesPerView: 4,
            // spaceBetween: 40
          },
          '640': {
            // slidesPerView: 5,
            spaceBetween: 100
          },
        },
        // slidesPerGroup: 3,
        loop: true,
        grabCursor: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        // Some Swiper option/callback...
      }
    }
  },
  components: {
    ContactForm
  },
  props: ['apartment', 'floor'],
  computed: {
    planImages() {
      return this.$flats[this.apartment.toString()].plans.items.map(element => this.$linksRules.plansLarge + this.apartment + '/' + element + this.$flats[this.apartment.toString()].plans.format )
    },
    nextFlatLink() {
      if(parseInt(this.apartment) == Object.keys(this.$building.floor[this.floor].apartment).length) {
        return '/plan/floor/' +  this.floor + '/apartment/' + Object.keys(this.$building.floor[this.floor].apartment)[0]
      } else {
        return '/plan/floor/' +  this.floor + '/apartment/' + Object.keys(this.$building.floor[this.floor].apartment)[Object.keys(this.$building.floor[this.floor].apartment).indexOf(this.apartment) + 1]
      }
    },
    prevFlatLink() {
      if(Object.keys(this.$building.floor[this.floor].apartment).indexOf(this.apartment) == 0) {
        return '/plan/floor/' +  this.floor + '/apartment/' + Object.keys(this.$building.floor[this.floor].apartment)[Object.keys(this.$building.floor[this.floor].apartment).length - 1]
      } else {
        return '/plan/floor/' +  this.floor + '/apartment/' + Object.keys(this.$building.floor[this.floor].apartment)[Object.keys(this.$building.floor[this.floor].apartment).indexOf(this.apartment) - 1]
      }
    }
  },
  methods: {
    goToFloor(floor) {
      // if(this.$building.floor[floor].type == 'live') {
      //
      // }
      console.log(parseInt(floor))
      console.log(parseInt(this.apartment))
    },
    toggleBodyClass(addRemoveClass, className) {
      const el = document.body;

      if (addRemoveClass === 'addClass') {
        el.classList.add(className);
      } else {
        el.classList.remove(className);
      }
    }
  },
  // mounted: function () {
  //   this.toggleBodyClass('addClass', 'plan-page')
  //   setTimeout(() => {  this.toggleBodyClass('addClass', 'selected-apartment') }, 10);
  // },
  beforeUpdate() {
    this.toggleBodyClass('addClass', 'plan-apartment-container-change')
    // setTimeout(() => {
    //   // this.toggleBodyClass('addClass', 'xx')
    //   // this.planContainerClass = 'plan-apartment-container plan-apartment-container-change'
    //   setTimeout(() => {  this.toggleBodyClass('addClass', 'plan-apartment-container-change') }, 10)
    // }, 10)
    console.log('/plan/floor/' + this.floor + '/')
  },
  updated() {
    setTimeout(() => {
      // this.planContainerClass = 'plan-apartment-container'
      // this.toggleBodyClass('removeClass', 'xx')
      setTimeout(() => {  this.toggleBodyClass('removeClass', 'plan-apartment-container-change') }, 10)
    }, 200)
    this.planSwiper.update()
    this.planSwiper.slideTo(1, 100, false)
    // this.swiper.updateAutoHeight(100)
  },
  mounted() {
    setTimeout(() => {
      this.toggleBodyClass('addClass', 'plan-page')
      setTimeout(() => {  this.toggleBodyClass('addClass', 'selected-apartment') }, 10)
    }, 10)
    console.log('/plan/floor/' + this.floor + '/')
    console.log('Current Swiper instance object', this.planSwiper)
    this.planSwiper.slideTo(1, 1000, false)
  },
  destroyed() {
    setTimeout(() => {  this.toggleBodyClass('removeClass', 'selected-apartment') }, 10)
  }
  // beforeCreate: function() {
  //     document.body.className = 'plan-page selected-floor';
  // }
}
</script>

<style lang="scss">
.fade-enter-active, .fade-leave-active {
  transition: opacity 2.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}

.plan-apartment-swiper {
  margin-top: 50px;
  margin-bottom: 50px;
}
.plan-apartment-data {
  transition: transform .2s, opacity .2s;
  opacity: 1;
}
.plan-apartment-container-change {
  .plan-apartment-data {
    transform: translateX(100px);
    opacity: 0;
  }
}
</style>
