<template>
  <div>
  <a class="gent-button" @click="modal.show()">Залишити заявку</a>
  <div class="modal fade" ref="exampleModal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Замовити зворотній зв'язок</h5>
          <button type="button" class="btn-close" @click="modal.hide()" aria-label="Close"></button>
        </div>
        <div class="modal-body" v-if="modalSuccesed">
          Дякуємо, ми зв'яжемось з Вами найближчим часом!
        </div>
        <div class="modal-body" v-else>
          <form @submit.prevent="onSubmit">
            <div class="row mb-3">
              <label for="inputEmail3" class="col-sm-6 text-right col-form-label">Ім’я</label>
              <div class="col-sm-6">
                <input type="text" v-model="clientName" class="form-control"  />
              </div>
            </div>
            <div class="row mb-3">
              <label for="inputPassword3" class="col-sm-6 text-right col-form-label">Телефон або е-мейл</label>
              <div class="col-sm-6">
                <input type="text" v-model="clientContact" class="form-control"  />
              </div>
            </div>
            <button type="submit" class="btn btn-primary">Відправити заявку</button>
          </form>
        </div>
        <!-- <div class="modal-footer">
        </div> -->
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { Modal } from 'bootstrap'

export default {
  name: 'carrousel',
  data() {
    return {
      modal: null,
      modalSuccesed: false,
      clientName: '',
      clientContact: '',
    }
  },
  methods: {
    onSubmit(id) {
      console.log("submit", id);
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ clientName: this.clientName, clientContact: this.clientContact, clientType: "apartment" })
      };
      console.log(requestOptions)
      fetch("https://gentleman-park.com.ua/api/", requestOptions)
        .then(response => response.text())
        // .then(data => (this.postId = data.id));
        .then(this.modalSuccesed = true)
        .then(setTimeout(() => {  this.modal.hide() }, 3000))
        .then(this.modalSuccesed = false)
        .then(data => console.log(data));
      // if (this.title.trim()) {
      //   const newTodo = {
      //     id: Date.now(),
      //     title: this.title,
      //     completed: false
      //   }
      //   this.$emit('add-todo', newTodo)
      //   this.title = ''
      // }
    }
  },
  mounted() {
    this.modal = new Modal(this.$refs.exampleModal)
  }
}
</script>
